import React from 'react'
import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import color from '../color-css/color'

export type Props = {
    noteOnPayment: string[]
}

const NoteOfPaymentContainer = styled.div`
    margin-top: 3.125rem;
`

const StyledCheckIcon = styled(FontAwesomeIcon)`
    position: absolute;
    color: ${color.blue_darker};
    margin-top: 0.4375rem;
    font-size: 0.875rem;
    // width: 1.375rem !important;
    text-align: center;
    // margin-left: -0.375rem;
`

const StyledNoteTitle = styled.p`
    font: 700 1rem museo-sans, sans-serif;
    letter-spacing: -0.05px;
    color: ${color.grey_29_percent};
    @media only screen and (max-width: 768px) {
        font-size: 0.9375rem;
    }
`

const StyledNoteContent = styled.div`
    font: 500 1rem museo-sans, sans-serif;
    letter-spacing: -0.05px;
    color: ${color.grey_29_percent};
    @media only screen and (max-width: 768px) {
        font-size: 0.9375rem;
    }
`

const StyledNodeContentText = styled.p`
    line-height: 1.75rem;
    margin-left: 1.125rem;
    margin-bottom: 1rem;
`

export const NoteOfPayment: React.FC<Props> = ({
    noteOnPayment
}) => {
    const noteContents = noteOnPayment.map((each, i) =>
        (<StyledNoteContent key={`notes-key-${i}`}>
            <span><StyledCheckIcon icon={faChevronRight} /></span>
               <StyledNodeContentText> {each} </StyledNodeContentText>
            </StyledNoteContent>)
    )

    return (
        <NoteOfPaymentContainer>
            <StyledNoteTitle>Notes on payments</StyledNoteTitle>
            {noteContents}
        </NoteOfPaymentContainer>
    )
}

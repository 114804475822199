import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import { getMessages } from '../dataProcessing'
import TextInput from '../TextInput/index'
import AddressAutosuggest from '../AddressAutosuggest/SearchLocationInput'
import SelectDropdown from '../SelectDropdown/index'
import HorizontalLine from '../HorizontalLine'
import state from '../../assets/data/state.json'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'
import { animateScroll as scroll } from 'react-scroll'

import {
  BILLING_FIRST_NAME,
  BILLING_LAST_NAME,
  BILLING_ADDRESS,
  BILLING_UNIT_NUMBER,
  BILLING_CITY,
  BILLING_STATE_OR_TERITORY,
  BILLING_POSTCODE,
  BILLING_ADDRESS_OPTION,
  DIFFERENT_BILLING_ADDRESS_DATA,
} from '../FormHighOrder/types/YourContributionField'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import InputFieldContainer from '../Commons/InputFieldContainer'
import {
  writeForm4BillingAddressInfo
} from '../FormHighOrder/features/index'

export type Props = {
  onBillingAddressDataValidated?: (wasSuccessful: boolean) => void,
  onDifferentBillingAddressData?: (data: any) => void
  inputFromProps?: string
  fetchDataFromProps?: (value: string) => void
}

const BillingAddressFieldsContainer = styled.div`
  animation-name: flickity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  @keyframes flickity {
      0% {
          opacity:0;
      }
      100% {
          opacity:1;
      }
  };
  @media screen and (min-width: 1024px) {
    max-width: 40.9375rem;
  }
  @media (max-width: 690px) {
      margin-left: 0;
  };
`

const BillingAddressFieldsForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 40.9375rem;
  height: 100%;
  @media (max-width: 690px) {
      display: block;
      width: 100%;
  }
`

const StateWrapper = styled.div`
  margin-top: 2.1875rem;
  @media (max-width: 1024px) {
    margin-top: 2.1875rem;
  }
`

const BillingAddressFields: React.FC<Props> = () => {

  interface ReactRef {
    [label: string]: React.RefObject<HTMLInputElement>,
  }

  const dispatch = useDispatch()

  const [cityValue, setCityValue] = useState('')
  const [stateOrTerritoryValue, setStateOrTerritoryValue] = useState('')
  const [postcodeValue, setPostcodeValue] = useState('')
  const [isBillingAddressScrolled, setIsBillingAddressScrolled] = useState(false)

  const scrollToBillingAddress = () => {
    if (isMobileOnly && isBillingAddressScrolled === false) {
      setIsBillingAddressScrolled(true)
      scroll.scrollTo(800);
    }
    if (isTablet && isBillingAddressScrolled === false) {
      setIsBillingAddressScrolled(true)
      scroll.scrollTo(550);
    }
    else return
  };

  const Refs: ReactRef = {
    [BILLING_FIRST_NAME]: useRef<HTMLInputElement>(null),
    [BILLING_LAST_NAME]: useRef<HTMLInputElement>(null),
    [BILLING_ADDRESS]: useRef<HTMLInputElement>(null),
    [BILLING_UNIT_NUMBER]: useRef<HTMLInputElement>(null),
    [BILLING_CITY]: useRef<HTMLInputElement>(null),
    [BILLING_STATE_OR_TERITORY]: useRef<HTMLInputElement>(null),
    [BILLING_POSTCODE]: useRef<HTMLInputElement>(null),
  }

  const getValidate = useSelector((state: RootState) => {
    const { data } = state.FormHighOrder.pageData[3]
    return data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_FIRST_NAME].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_LAST_NAME].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_ADDRESS].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_UNIT_NUMBER].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_CITY].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_STATE_OR_TERITORY].isValidated
      && data[DIFFERENT_BILLING_ADDRESS_DATA].value[BILLING_POSTCODE].isValidated
  })

  const getPrefetchData =
    useSelector((state: RootState) =>
      state.FormHighOrder.pageData[3].data[DIFFERENT_BILLING_ADDRESS_DATA])

  const dispatchDifferentBillingAddress = (nameParams: string, data: string, validation: boolean) => {
    dispatch(writeForm4BillingAddressInfo({
      key: nameParams,
      value: data ? data : "",
      isValidated: validation
    }
    ))
  }

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  const inputFieldFactory = (children: React.ReactNode) => {
    return (
      <InputFieldContainer>
        {children}
      </InputFieldContainer>)
  }

  const createInputField = (
    label: string,
    placeHolderParam: string,
    nameParam: string,
    maxLength?: number,
    labelNotRequire?: string,
    type?: string,
    getCityFromAddress?: string,
    getPostCodeFromAddress?: string,
    aRef?: React.RefObject<HTMLInputElement>,
    width?: string,
    hideValidate?: any): React.ReactNode => {
    return (
      <TextInput
        placeholder={placeHolderParam}
        name={nameParam}
        type={type || "text"}
        maxLength={maxLength}
        {...{ label, message: getMessages, labelNotRequire }}
        onInputValueCallback={(value, isValidated) => {
          dispatchDifferentBillingAddress(nameParam, value, isValidated)
        }}
        ref={aRef}
        widthSize={width}
        inputFromProps={checkFetchData(nameParam) ?
          getPrefetchData.value[nameParam]?.value : ""}
        isValidated={getPrefetchData.value[nameParam]?.isValidated}
        getCityFromAddress={getCityFromAddress}
        getPostCodeFromAddress={getPostCodeFromAddress}
        hideValidate={hideValidate}
      />
    )
  }

  return (
    <BillingAddressFieldsContainer>
      <BillingAddressFieldsForm>
        {inputFieldFactory(
          createInputField("First name", "", BILLING_FIRST_NAME,
            undefined, undefined, 'text', undefined, undefined, Refs[BILLING_FIRST_NAME])
        )}

        {inputFieldFactory(
          createInputField("Last name", "", BILLING_LAST_NAME,
            undefined, undefined, 'text', undefined, undefined, Refs[BILLING_LAST_NAME])
        )}

        <div style={{ marginTop: '2.5rem' }}
          onClick={() => {
            scrollToBillingAddress()
          }}
        >
          <AddressAutosuggest
            label='Billing address'
            placeholder=''
            ref={Refs[BILLING_ADDRESS]}
            handleAddressValue={(address, wasAnInputSuccess) => {
              dispatchDifferentBillingAddress(BILLING_ADDRESS, address, wasAnInputSuccess)
            }}
            inputFromProps={checkFetchData(BILLING_ADDRESS) ?
              getPrefetchData.value[BILLING_ADDRESS]?.value : undefined}
            isValidated={getPrefetchData.value[BILLING_ADDRESS]?.isValidated}
            handleAddressInfo={(city, state, postcode) => {
              setCityValue(city)
              setStateOrTerritoryValue(state)
              setPostcodeValue(postcode)
            }}
          />
        </div>

        {inputFieldFactory(
          createInputField("", "", BILLING_UNIT_NUMBER,
            undefined, "Unit number (if applicable)", 'text', undefined, undefined, Refs[BILLING_UNIT_NUMBER], undefined, true)
        )}

        {inputFieldFactory(
          createInputField("City", "", BILLING_CITY,
            undefined, undefined, 'text', cityValue, undefined, Refs[BILLING_CITY])
        )}

        <StateWrapper>
          <SelectDropdown
            label="State / territory"
            name={BILLING_STATE_OR_TERITORY}
            contentDefault="Select a state"
            data={state}
            onSelectedValueProp={value => {
              dispatchDifferentBillingAddress(BILLING_STATE_OR_TERITORY, value, true)
            }}
            ref={Refs[BILLING_STATE_OR_TERITORY]}
            inputFromProps={checkFetchData(BILLING_STATE_OR_TERITORY) ?
              getPrefetchData.value[BILLING_STATE_OR_TERITORY]?.value : undefined}
            isValidated={getPrefetchData.value[BILLING_STATE_OR_TERITORY]?.isValidated}
            getStateFromAddress={stateOrTerritoryValue}
          />
        </StateWrapper>

        {inputFieldFactory(
          createInputField("Postcode", "", BILLING_POSTCODE,
            4, undefined, 'tel', undefined, postcodeValue, Refs[BILLING_POSTCODE])
        )}

      </BillingAddressFieldsForm>
      <div style={{
        marginTop: `3.125rem`,
        marginBottom: `3.125rem`
      }}>
        <HorizontalLine />
      </div>

    </BillingAddressFieldsContainer >
  )
}

export default BillingAddressFields

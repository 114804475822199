import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'

import styled from 'styled-components'

import TextInput from '../TextInput/index'
import { getMessages } from '../dataProcessing'
import SelectDropdown from '../SelectDropdown/index'
import SummarySection from '../SummarySection'
import dayOfWeek from '../../assets/data/dayOfWeek.json'
import paymentDeducted from '../../assets/data/paymentDeducted.json'
import InputFieldContainer from '../Commons/InputFieldContainer'

import { getStartDate, getDebitAmount } from '../summarySectionProcessing'

import {
    CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
    CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
    CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
    CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
    CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
    CREDITCARD_PAYMENT_DATE_OF_DEDUCTION,
    CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION,
    CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION,
    CREDITCARD_PAYMENT_TAB_DATA
} from '../FormHighOrder/types/YourContributionField'

import {
    EMPLOYMENT_STATUS,
    EMPLOYMENT_STATUS_CASUAL
} from '../FormHighOrder/types/AboutYouField'

import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'
import {
    writeForm4CreditCardTabData
} from '../FormHighOrder/features/index'
import ReactRef from 'components/FormHighOrder/types/ReactRef'

import branchSpecific from '../../utils/branchSpecificCopy'

import FatZebra from './MerchantSpecific/fatzebra'
import BPoint from './MerchantSpecific/bpoint'
import PayWay from './MerchantSpecific/payway'

export type Props = {
    onCreditCardPaymentTabValidated?: (wasSuccessful: boolean) => void
    onCreditCardPaymentData?: (data: any) => void
    isCreditCardDataChange?: (isChange: boolean) => void
    inputFromProps?: string
    isValidated?: boolean
}

interface CreditCardPaymentContainer {
    onCreditCardPaymentTabContent?: (nameParam: string, inputContent: string) => void
}

const CreditCardPaymentContainer = styled.div<CreditCardPaymentContainer>`
    animation-name: flickity;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes flickity {
        0% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
    };
    @media (max-width: 690px) {
        margin-left: 0;
    }
`

export const CreditCardPaymentForm = styled.div`
    width: 39.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 690px) {
        display: block;
        width: 100%;
    }
`

export const Wrapper = styled.div`
    display: flex;
    width: 29.75rem;
    justify-content: space-between;
    @media (max-width: 690px) {
        display: grid;
        width: 100%;
        grid-template-columns: 49.5% 49.5%;
        grid-column-gap: 1%;
    }
`
const Wrapper2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.5rem;
    @media (max-width: 690px) {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 1.0625rem;
        width: 100%;
    }
`

interface FrequencyAvailable {
    isFrequencyAvailable?: boolean
}

interface DeductionDayAvailable {
    isDeductionDayAvailable?: boolean
}

const FrequencyAvailable = styled.div<FrequencyAvailable>`
    display: ${props => props.isFrequencyAvailable ? "" : "none"};
    width: 18.875rem;
    @media (max-width: 690px) {
        width: 100%;
    }
`

const DeductionDayAvailable = styled.div<DeductionDayAvailable>`
    display: ${props => props.isDeductionDayAvailable ? "" : "none"};
    width: 19.375rem;
    @media (max-width: 690px) {
        width: 100%;
    }
`

const CreditCardPayment: React.FC<Props> = ({ }) => {

    const dispatch = useDispatch()
    const getPrefetchData = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA])

    const getPrefetchDataForm2 = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[1].data)

    const getStateFromForm1 = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

    const checkFetchData = (nameParam: string) => {
        return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
    }

    const dispatchCreditCardData = (nameParams: string, data: string, dataValidated: boolean) => {
        dispatch(writeForm4CreditCardTabData({
            key: nameParams,
            value: data ? data : "",
            isValidated: dataValidated
        }
        ))
    }

    const [summaryDebitAmount, setSummaryDebitAmount] = useState('')
    const [summaryStartDate, setSummaryStartDate] = useState('')
    const [summaryFrequency, setSummaryFrequency] = useState('')

    const [isFrequencyAvailable, setIsFrequencyAvailable] = React.useState<any>(true)
    const [isDeductionDayAvailable, setIsDeductionDayAvailable] = React.useState<any>(true)

    const Refs: ReactRef = {
        [CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]: useRef<HTMLInputElement>(null),
    }

    useEffect(() => {
        if (['QLD', 'SA', 'TAS', 'NT', 'Broken Hill'].includes(getStateFromForm1)) {
            if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
                || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
                || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 22.60, getStateFromForm1))
            } else if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 16.10, getStateFromForm1))
            } else if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 9.00, getStateFromForm1))
            }
        } else if (['WA'].includes(getStateFromForm1)) {
          if (
            getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
            || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
            || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
          ) {
            setSummaryDebitAmount(getDebitAmount(summaryFrequency, 21.00, getStateFromForm1))
          } else if (
            getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
          ) {
            setSummaryDebitAmount(getDebitAmount(summaryFrequency, 15.00, getStateFromForm1))
          } else if (
            getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
          ) {
            setSummaryDebitAmount(getDebitAmount(summaryFrequency, 8.40, getStateFromForm1))
          }
        } else {
            if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
                || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
                || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 20.40, getStateFromForm1))
            } else if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 14.60, getStateFromForm1))
            } else if (
                getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
            ) {
                setSummaryDebitAmount(getDebitAmount(summaryFrequency, 8.20, getStateFromForm1))
            }
        }
    }), [getPrefetchDataForm2[EMPLOYMENT_STATUS].value, getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value, summaryFrequency]

    useEffect(() => {
        if (getStateFromForm1 === 'SA' || getStateFromForm1 === 'NT'
            || getStateFromForm1 === 'Broken Hill') {
            setIsFrequencyAvailable(false)
            setSummaryFrequency('Fortnightly')
            setSummaryStartDate(getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value)
        }
        else if (getStateFromForm1 === 'QLD') {
            setIsDeductionDayAvailable(false)
            setSummaryFrequency(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
            setSummaryStartDate('noStartDate')
        }
        else if (getStateFromForm1 === 'WA') {
            setIsDeductionDayAvailable(false)
            setIsFrequencyAvailable(false)
            setSummaryFrequency(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
            setSummaryStartDate('noStartDate')
        }
        else if (getStateFromForm1 === 'TAS') {
            setIsDeductionDayAvailable(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value?.toLowerCase() !== 'monthly')
            setSummaryFrequency(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
            setSummaryStartDate(getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value)
            setSummaryStartDate(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value?.toLowerCase() === 'monthly' ? 'firstOfNextMonth' : getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value)
          }
        else {
            setIsFrequencyAvailable(true)
            setIsDeductionDayAvailable(true)
            setSummaryStartDate(getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value)
            setSummaryFrequency(getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
        }
    }, [getStateFromForm1,
        getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value,
        getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value
    ])

    const inputFieldFactory = (children: React.ReactNode) => {
        return (
            <InputFieldContainer>
                {children}
            </InputFieldContainer>)
    }

    const fetchSummaryStartDate = getStartDate(summaryStartDate)

    const createInputField = (
        label: string,
        placeHolderParam: string,
        nameParam: string,
        maxLength?: number,
        widthSize?: string,
        tooltipName?: string,
        type?: string,
        aRef?: React.RefObject<HTMLInputElement>,
    ): React.ReactNode => {
        return (
            <TextInput
                placeholder={placeHolderParam}
                name={nameParam}
                type={type}
                maxLength={maxLength}
                widthSize={widthSize}
                {...{ label, message: getMessages, tooltipName }}
                ref={aRef}
                onInputValueCallback={(value, isValidated) => {
                    dispatchCreditCardData(nameParam, value, isValidated)
                }}
                inputFromProps={
                    checkFetchData(nameParam) ?
                        getPrefetchData.value[nameParam]?.value : ""
                }
                isValidated={
                    getPrefetchData.value[nameParam]?.isValidated
                }
            />
        )
    }

    const getCreditCardForm = (): JSX.Element => {
        if (getStateFromForm1 === 'SA' || getStateFromForm1 === 'WA' ||
            getStateFromForm1 === 'TAS' || getStateFromForm1 === 'NT' ||
            getStateFromForm1 === 'Broken Hill' || getStateFromForm1 === 'QLD') {
            return (
                <FatZebra />
            )
        }
        if (getStateFromForm1 === 'Newcastle') {
            return (
                <PayWay />
            )
        }
        return (
            <>
                {inputFieldFactory(
                    createInputField("Cardholder’s first name",
                        "First name",
                        CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
                        undefined,
                        undefined,
                        undefined, 'text',
                        Refs[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME])
                )}

                {inputFieldFactory(
                    createInputField("Cardholder’s last name",
                        "Last name",
                        CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
                        undefined,
                        undefined,
                        undefined, 'text',
                        Refs[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME])
                )}

                {inputFieldFactory(
                    createInputField("Card number",
                        "_ _ _ _ / _ _ _ _ / _ _ _ _ / _ _ _ _",
                        CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER, 19,
                        undefined,
                        undefined, 'tel',
                        Refs[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER])
                )}

                <Wrapper>
                    {inputFieldFactory(
                        createInputField("Expiry date",
                            "_ _ / _ _",
                            CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE, 7, "9.1875rem",
                            undefined, 'tel',
                            Refs[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE])
                    )}

                    {inputFieldFactory(
                        createInputField("CVC / CVV", "_ _ _",
                            CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV, 3, "9.1875rem", "cvc",
                            'tel', Refs[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV])
                    )}
                </Wrapper>
            </>
        )
    }

    return (
        <CreditCardPaymentContainer>

            <CreditCardPaymentForm>
                {getCreditCardForm()}

                <Wrapper2>
                    <FrequencyAvailable isFrequencyAvailable={isFrequencyAvailable}>
                        <SelectDropdown
                            label="How often would you like your payments to be deducted?"
                            name={CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION}
                            contentDefault="Select frequency"
                            data={paymentDeducted}
                            onSelectedValueProp={value => {
                                dispatchCreditCardData(CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION, value, true)
                            }}
                            ref={Refs[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]}
                            inputFromProps={checkFetchData(CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION) ?
                                getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value : undefined}
                            isValidated={getPrefetchData.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.isValidated}
                        />
                    </FrequencyAvailable>
                    {isDeductionDayAvailable && (
                      <DeductionDayAvailable isDeductionDayAvailable={isDeductionDayAvailable}>
                          <SelectDropdown
                            label="Which day of the week would you like your payments to be deducted?"
                            name={CREDITCARD_PAYMENT_DATE_OF_DEDUCTION}
                            contentDefault="Select day"
                            data={dayOfWeek}
                            ref={Refs[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]}
                            onSelectedValueProp={value => {
                                dispatchCreditCardData(CREDITCARD_PAYMENT_DATE_OF_DEDUCTION, value, true)
                            }}
                            inputFromProps={checkFetchData(CREDITCARD_PAYMENT_DATE_OF_DEDUCTION) ?
                                            getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value : undefined}
                            isValidated={getPrefetchData.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.isValidated}
                          />
                      </DeductionDayAvailable>
                    )}
                </Wrapper2>

            </CreditCardPaymentForm>

            <SummarySection
                paymentAuthorisationContent={branchSpecific(getStateFromForm1, 'paymentAuthority', 'creditCard')}
                paymentAgreementProp={branchSpecific(getStateFromForm1, 'paymentAgreement', 'creditCard')}
                summaryTableDataProp={[
                    {
                        title: "Debit amount:",
                        value: summaryDebitAmount
                    },

                    ...(fetchSummaryStartDate !== null ? [{
                        title: "Start date:",
                        value: fetchSummaryStartDate ? fetchSummaryStartDate : '-- / -- / --'
                    }] : []),

                    {
                        title: "Frequency:",
                        value: summaryFrequency
                    },

                    {
                        title: "Duration:",
                        value: "Ongoing"
                    },

                ]}
                noteOnPaymentProp={branchSpecific(getStateFromForm1, 'notesOnPayment')}
                onSelectAgreeTermAndCondition={wasSelected => {
                    dispatchCreditCardData(CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION, wasSelected ? 'true' : '', wasSelected ? true : false)
                }}
                hasTermCheckbox={true}
                inputFromProps={getPrefetchData.value[CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION]?.value}
                isValidated={getPrefetchData.value[CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION]?.isValidated}
            />
        </CreditCardPaymentContainer>
    )
}

export default CreditCardPayment

import React, { useState, forwardRef, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import color from '../color-css/color'
import TextInput from '../TextInput/index'
import ValidationIndicator from '../ValidationIndicator'
import RadioButtonGroup from '../RadioButtonGroup'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'

import {
  EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_CASUAL
} from '../FormHighOrder/types/AboutYouField'
import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'
import ReactRef from '../FormHighOrder/types/ReactRef'
import { ScrollBar } from '../ScrollBarComponent'

export type Props = {
  contentDefault?: string
  label?: string
  data?: any
  name?: string
  onSelectedItemProp?: (wasSelected: boolean) => void
  onSelectedValueProp?: (selectedValue: string) => void
  noValidationIndicator?: boolean
  isValidated?: boolean
  inputFromProps?: string
  inputOnAverageFromProps?: string
  onAverageData?: (data: any) => void
  getStateFromAddress?: string
  onAverageSelected?: (wasSelected: boolean) => void
}

interface IconProps {
  willInteract?: boolean
}

const StyledIcon = styled(({ willInteract, ...rest }) => <FontAwesomeIcon {...rest} />) <IconProps>`
  position: absolute;
  color: ${props => props.willInteract ? color.tooltip_icon : color.blue_darker};
  margin-top: -1.75rem;
  margin-left: 16.875rem;
  height: 0.875rem;
  transform: ${props => props.willInteract ? 'rotate(180deg)' : ''}
`

const StyledIconMobile = styled(StyledIcon)`
  position: absolute;
  color: ${props => props.willInteract ? color.tooltip_icon : color.blue_darker};
  margin-top: -1.75rem;
  margin-left: 16.875rem;
  height: 0.875rem;
  transform: ${props => props.willInteract ? 'rotate(180deg)' : ''}
`

interface DropdownContainer {
  visibility: string
}

const DropdownContainer = styled.div<DropdownContainer>`
  display: ${props => props.visibility === 'true' ? 'block' : 'none'};
  position: absolute;
  margin-top: 0.875rem;
  width: 18.9375rem;
  max-height: 13rem;
  background: ${color.white} 0% 0% no-repeat padding-box;
  box-shadow: 0 1.25rem 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  border-radius: 0.625rem;
  z-index: 1111111;
  &:before {
    content: "";
    position: absolute;
    left: 9.0625rem;
    top: -1.25rem;
    width: 0;
    height: 0;
    border-top: 0.9375rem solid transparent;
    border-right: 0.9375rem solid #fff;
    border-bottom: 0.875rem solid transparent;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
`

const DropdownSuggestWrapped = styled.div`
  max-height: 9.75rem;
  ::-webkit-scrollbar {
    width: 0.8125rem;
  }
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #EFEFEF;
  border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
  background: #EFEFEF;
  border-radius: 0.625rem;
  }
  @media (max-width: 690px) {
    width: 100%;
  }
`

interface DropdownSuggestContentWrapped {
  value?: (input: string) => string,
}

const DropdownSuggestContentWrapped = styled.span<DropdownSuggestContentWrapped>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: museo-sans, sans-serif;
  margin: 2rem 1.1875rem 2.25rem 0;
  max-height: 9.75rem;
  padding: 0 1.75rem 0 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: ${color.grey_darker};
  cursor: pointer;
  overflow: auto;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  };

  ${ScrollBar}
`

interface Select {
  inputSuccessAsBackgroundColor: boolean,
}

const Select = styled.select<Select>`
  width: calc(100% - 1.875rem);
  height: 1.1875rem;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  background-color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue_17_percent : color.grey_dark};
  font-size: 1rem;
  padding: 0.75rem;
  padding-left: 0.9375rem;
  margin: 1.25rem 0 0 0;
  border-radius: 0.625rem;
  -webkit-appearance: none;
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
`

const DesktopVersion = styled.div`
  @media (max-width: 690px) {
    display: none;
  }
`

const MobileVersion = styled.div`
  display: none;
  @media (max-width: 690px) {
    display: block;
  }
`

const DropdownSuggestContent = styled.span``

const DropdownSuggestContentKey = styled.div`
  margin-bottom: 0.8125rem;
  outline: none;
  &:hover ${DropdownSuggestContent} {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
  &:focus ${DropdownSuggestContent} {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
`

const PositioningRadioButtonGroup = styled(RadioButtonGroup)`
  margin-top: 2.5rem;
`

const InputHover = styled.div`
  &:hover ${StyledIcon} {
    transition: color 0.5s ease-in-out;
    color: ${color.tooltip_icon}
  }
`

type Ref = HTMLInputElement

export const SelectDropdown = forwardRef<Ref, Props>((props: any, aRef: any) => {

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  const { contentDefault, label, data, name,
    onSelectedItemProp,
    onSelectedValueProp,
    inputFromProps,
    inputOnAverageFromProps,
    isValidated,
    noValidationIndicator,
    onAverageData, getStateFromAddress, onAverageSelected } = props;

  const [inputSuccess, setInputSuccess] = useState(false);
  const [validatorVisibilityState, setValidatorVisibility] = useState(false);
  const [showingDropdownState, setShowingDropdown] = useState(false);
  const [textInputContentState, setTextInputContent] = useState(contentDefault);
  const [showOnAverage, setShowOnAverage] = useState(false)
  const [validatorMsg, setValidatorMsg] = useState("")
  const [clickedOutsideState, setClickedOutside] = useState(false);
  const [paymentMethodSelection, setPaymentMethodSelection] = useState("")
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [interactIcon, setInteractIcon] = useState(false)
  const [onNext, setOnNext] = useState(0)

  const Refs: ReactRef = {
    [EMPLOYMENT_STATUS_CASUAL]: useRef<HTMLInputElement>(null),
  }

  useEffect(() => {
    onAverageData ? onAverageData({
      [EMPLOYMENT_STATUS_CASUAL]: {
        isValidated: true,
        value: paymentMethodSelection,
      }
    }) : {}
  }, [paymentMethodSelection])

  useEffect(() => {
    if (getStateFromAddress !== '' && getStateFromAddress !== undefined) {
      setTextInputContent(getStateFromAddress)
      onSelectedItemProp ? onSelectedItemProp(true) : {}
      onSelectedValueProp ? onSelectedValueProp(getStateFromAddress) : {}
      setInputSuccess(true)
      setValidatorVisibility(true)
    }
    else return
  }, [getStateFromAddress])

  useEffect(() => {
    if (inputFromProps !== undefined && isValidated !== false) {
      handleContent(inputFromProps)
      inputFromProps ? setTextInputContent(inputFromProps) : {}
      onSelectedItemProp ? onSelectedItemProp(isValidated) : {}
    }
  }, [inputFromProps, isValidated])

  useEffect(() => {
    if (validatorVisibilityState === true && inputSuccess === false) {
      setLabelIconStateState(false)
    } else {
      setLabelIconStateState(true)
    }
  }, [inputSuccess, validatorVisibilityState])

  const handleOnShow = (isShow: boolean) => {
    setShowingDropdown(isShow)
    setInteractIcon(isShow)
  }

  const handleContentMobile = (event: any) => {
    if (inputFromProps !== undefined) {
      setTextInputContent(inputFromProps)
    }
    setTextInputContent(event.target.value)
    if (event.target.value?.toLowerCase() === 'casual' || event.target.value?.toLowerCase() === 'part time') {
      setShowOnAverage(true)
      setValidatorMsg("")
      setInputSuccess(true)
    } else {
      setShowOnAverage(false)
      setValidatorMsg('Thanks')
      setInputSuccess(true)
      setValidatorVisibility(true)
    }
    onSelectedValueProp ? onSelectedValueProp(event.target.value) : {}
    onSelectedItemProp ? onSelectedItemProp(true) : {}

  }

  const handleContent = (textContent: string) => {
    setTextInputContent(textContent)

    if (textContent?.toLowerCase() === 'casual' || textContent?.toLowerCase() === 'part time') {
      setInputSuccess(true)
      setValidatorVisibility(true)
      setValidatorMsg('')
      setShowOnAverage(true)
    }
    else {
      setInputSuccess(true)
      textContent === 'Salaried' || textContent === 'Full Time' ? setValidatorMsg('Thanks') : setValidatorMsg(' ')
      setValidatorVisibility(true)
      setShowOnAverage(false)
    }
    onSelectedValueProp ? onSelectedValueProp(textContent) : {}
    onSelectedItemProp ? onSelectedItemProp(true) : {}
  }

  const handleClickOutsideRef = useOnclickOutside(() => {
    setShowingDropdown(false)
    setTextInputContent(textInputContentState)
    setClickedOutside(!clickedOutsideState)
    setInteractIcon(false)
  });

  useEffect(() => {
    if (showingDropdownState === false) {
      setClickedOutside(!clickedOutsideState)
    }
  }, [showingDropdownState])

  const handleState = (input: string) => {
    switch (input) {
      case 'Newcastle':
        return data.Newcastle
      case 'TAS':
        return data.TAS
      case 'QLD':
        return data.QLD
      case 'WA':
        return data.WA
      case 'SA' || 'NT' || 'Broken Hill':
        return []
      default:
        return data.Default
    }
  }

  const renderSuggestionByState = () =>
    handleState(getStateFromForm1).map((item: any, i: any) => {
      return (
        <DropdownSuggestContentKey
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleContent(e.target.textContent);
              handleOnShow(false);
            }
          }
          }

          key={i} onClick={(event: any) => {
            event.preventDefault()
            handleContent(event.target.textContent)
          }}>
          <DropdownSuggestContent onClick={(event: any) => {
            setShowingDropdown(false)
          }}>
            {item}
          </DropdownSuggestContent>
        </DropdownSuggestContentKey>
      )
    })

  const arrayRefs: HTMLDivElement[] = []

  const renderSuggestion = () =>
    data.options.map((item: any, i: any) => {
      return (
        <DropdownSuggestContentKey
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.key === 'ArrowDown') {
              setOnNext((onNext + 1))
              arrayRefs[(onNext + 1)]?.focus()
            }
            if (e.key === 'ArrowUp') {
              setOnNext((onNext - 1))
              arrayRefs[(onNext - 1)]?.focus()
            }
            if (e.key === 'Enter') {
              e.preventDefault()
              handleContent(e.target.textContent);
              handleOnShow(false);
            }
          }}
          key={i}
          onClick={(event: any) => {
            event.preventDefault()
            handleContent(event.target.textContent)
          }}
          ref={(ref: any) => { arrayRefs.push(ref) }}
        >
          <DropdownSuggestContent onClick={(event: any) => {
            setShowingDropdown(false)
          }}>
            {item}
          </DropdownSuggestContent>
        </DropdownSuggestContentKey>
      )
    })

  const renderSuggestionByStateMobile = () =>
    handleState(getStateFromForm1).map((item: any, i: any) => {
      return (
        <option value={item} key={`state-key-${i}`}>
          {item}
        </option>
      )
    })

  const renderSuggestionMobile = () =>
    data.options.map((item: any, i: any) => {
      return (
        <option value={item} key={`suggestion-key-${i}`}>
          {item}
        </option>
      )
    })

  return (
    <div>
      {label && <Label>{label} <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon></Label>}
      <DesktopVersion>
        <InputHover>
          <TextInput placeholder={contentDefault} widthSize="15.6875rem" name={name} type="text"
            onShow={isShow => handleOnShow(isShow)}
            inputFromProps={textInputContentState}
            readOnly={true}
            ref={aRef}
            onKeyDown={(e: any) => {
              if (e.key === 'ArrowDown') {
                e.preventDefault()
                e.stopPropagation()
                arrayRefs[onNext]?.focus()
              }
              if (e.key === 'Enter') {
                e.preventDefault()
                handleOnShow(!showingDropdownState);
              }
            }
            }
            isValidated={validatorVisibilityState}
            clickedProp={clickedOutsideState}
          />
          <StyledIcon willInteract={interactIcon} icon={faChevronDown} />
        </InputHover>
        <DropdownContainer
          visibility={showingDropdownState ? 'true' : 'false'}
          ref={handleClickOutsideRef}
        >
          <DropdownSuggestWrapped>
            <DropdownSuggestContentWrapped>
              {(data.Newcastle !== undefined || data.TAS !== undefined
                || data.QLD !== undefined || data.WA !== undefined || data.Default !== undefined) && renderSuggestionByState()}
              {data.options !== undefined && renderSuggestion()}
            </DropdownSuggestContentWrapped>
          </DropdownSuggestWrapped>
        </DropdownContainer>
      </DesktopVersion>
      <MobileVersion>
        <Select
          name={name}
          onChange={(event) => {
            handleContentMobile(event)
          }}
          onClick={() => setInteractIcon(!interactIcon)}
          onBlur={() => {
            if (interactIcon) { setInteractIcon(false) }
          }}
          ref={aRef}
          inputSuccessAsBackgroundColor={inputSuccess}
        >
          <option>{textInputContentState}</option>
          {(data.Newcastle !== undefined || data.TAS !== undefined
            || data.QLD !== undefined || data.WA !== undefined) && renderSuggestionByStateMobile()}
          {data.options !== undefined && renderSuggestionMobile()}
        </Select>
        <StyledIcon
          style={{ marginTop: '2.25rem', marginLeft: '-2.125rem' }}
          willInteract={interactIcon}
          icon={faChevronDown} />
      </MobileVersion>
      {!noValidationIndicator &&
        <ValidationIndicator isCorrect={inputSuccess} {...{ message: validatorMsg, visibility: validatorVisibilityState }} />
      }
      <PositioningRadioButtonGroup
        visibility={showOnAverage ? 'true' : 'false'}
        label="On average, how many hours do you work a week?"
        title1="0-9 hours"
        title2="10-19 hours"
        title3="20+ hours"
        onSelectedValue={(value) => {
          setInputSuccess(value !== '')
          setValidatorVisibility(value !== '')
          setPaymentMethodSelection(value)
        }
        }
        onChangeActiveID={(isChange) => {
          onAverageSelected ? onAverageSelected(isChange) : {}
        }}
        inputFromProps={
          inputOnAverageFromProps
        }
      />
    </div >
  )
})

export default SelectDropdown;

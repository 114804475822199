import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import CheckboxButtonGroup, { DIFFERENT_BILLING_ADDRESS_VALUE } from '../CheckboxButtonGroup'
import RadioButtonGroup from '../RadioButtonGroup'
import BankAccountTab from '../BankAccountTab'
import CreditCardPaymentTab from '../CreditCardPayment'
import PayDeductionTab from '../PayDeductionTab'
import BillingAddressFields from '../BillingAddress/BillingAddressFields'
import ProgressButton, { ButtonStates } from '../ProgressButton'
import H3 from '../H3'
import ProgressButtonPositioningDiv from '../ProgressButton/ProgressButtonPositioningDiv'
import {
  formSubmitted, setUnsuccessfullyPosted
} from '../FormHighOrder/features/index'
import { STATE_FROM_SUBURB, EMPLOYER_SELECTED_OR_TYPED, PAYROLL_DEDUCTION_REQUIRED } from '../FormHighOrder/types/YourWorkField'
import { EMPLOYMENT_STATUS } from '../FormHighOrder/types/AboutYouField'

import jump from 'jump.js'

import { useSelector, useDispatch } from 'react-redux'
import { RootState, store } from '../../store'
import {
  writeYourContributionData,
  writeValidateData
} from '../FormHighOrder/features/index'

import {
  CREDITCARD_PAYMENT_TAB_DATA,
  BANKACCOUNT_PAYMENT_TAB_DATA,
  PAY_DEDUCTION_PAYMENT_TAB_DATA,
  BILLING_ADDRESS_OPTION,
  DIFFERENT_BILLING_ADDRESS_DATA,
  PAYMENT_METHOD_SELECTION
} from '../FormHighOrder/types/YourContributionField'

import { handlePageSubmission } from '../../utils/submitData'

import branchSpecific from '../../utils/branchSpecificCopy'

import { tokenizeCard, iframeHeight, iframeErrorHeight, iframeId } from '../CreditCardPayment/MerchantSpecific/fatzebra'

import { navigate } from 'gatsby-link'

export type Props = {
  onFormValidatedProp?: (isValidated: boolean) => void,
  onHandleFormValidated?: (isValidate: boolean) => void
}

const FormStep4Wrapper = styled.div`
  width: 40.9375rem;
  position: relative;
  animation-name: flickity;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transform: translate3d(0, 0, 0);
  @keyframes flickity {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
  };
  @media (max-width: 1024px) {
    margin: 0 auto;
  };
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`

const PositioningRadioButtonGroup = styled(RadioButtonGroup)`
  margin-top: 2.5rem;
`

const CustomizedProgressButtonPositioningDiv = styled(ProgressButtonPositioningDiv)`
  position: relative;
`

const FormStepFour: React.FC<Props> = ({ onFormValidatedProp, onHandleFormValidated }) => {
  const dispatch = useDispatch()

  const [paymentTab, setPaymentTab] = useState(-1)

  const isIE = !!window?.MSInputMethodContext

  // Three states bellow are represented for:
  // 1. If user select on billing address field;
  // 2. billingAddressOption = home or different;
  // 3. if billingAddressOption === different, are those input validated?
  const [isBillingAddressSelected, setIsBillingAddressSelected] = useState(false)
  const [billingAddressOption, setBillingAddressOption] = useState('')
  // Payment method selection
  const [paymentMethodSelection, setPaymentMethodSelection] = useState('')

  const [proceedingButtonState, setProceedingButtonState] = useState(ButtonStates.disabled)
  const [progressButtonTilte, setProgressButtonTilte] = useState('Finish & submit')

  const [payrollDeductionTitle, setPayrollDeductionTitle] = useState('')
  const [isPayrollDeductionAvailable, setIsPayrollDeductionAvailable] = useState(true)

  const [thirdPartyCCValidated, setThirdPartyCCValidated] = useState(true)

  const isPaymentMethodSelectionValidated = (data: any) =>
    data[CREDITCARD_PAYMENT_TAB_DATA].isValidated
    || data[BANKACCOUNT_PAYMENT_TAB_DATA].isValidated
    || data[PAY_DEDUCTION_PAYMENT_TAB_DATA].isValidated

  const isDifferentBillingAddressSelectionValidated = (data: any) =>
    data[DIFFERENT_BILLING_ADDRESS_DATA].isValidated

  const getValidate = useSelector((state: RootState) => {
    const { data } = state.FormHighOrder.pageData[3]
    if (billingAddressOption === DIFFERENT_BILLING_ADDRESS_VALUE) {
      return isPaymentMethodSelectionValidated(data) && isDifferentBillingAddressSelectionValidated(data)
    }
    else return isPaymentMethodSelectionValidated(data)
  })

  const getPrefetchData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data)

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  const getPayDeductionRequiredFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[PAYROLL_DEDUCTION_REQUIRED].value)

  const getEmployerSelectedOrTypedFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[EMPLOYER_SELECTED_OR_TYPED].value)

  let bankAccountData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA])

  let creditCardData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA])

  let payDeductionData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[PAY_DEDUCTION_PAYMENT_TAB_DATA])

  let differentBillingData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[DIFFERENT_BILLING_ADDRESS_DATA])

  const getStateFromForm2 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[1].data[EMPLOYMENT_STATUS].value)

  const bankAccountPaymentValidation = bankAccountData.isValidated
  const creditCardPaymentValidation = creditCardData.isValidated
  const payDeductionPaymentValidation = payDeductionData.isValidated
  const billingAddressValidation = differentBillingData.isValidated

  useEffect(() => {

    if (getValidate && paymentMethodSelection === 'Bank account' &&
      (bankAccountPaymentValidation && isBillingAddressSelected)) {
      setProceedingButtonState(ButtonStates.enabled)
    }
    else if (getValidate && paymentMethodSelection === 'Credit card' &&
      (creditCardPaymentValidation && isBillingAddressSelected)) {
      setProceedingButtonState(ButtonStates.enabled)
    }
    else if (getValidate && paymentMethodSelection === 'Payroll deduction' &&
      (payDeductionPaymentValidation && isBillingAddressSelected)) {
      setProceedingButtonState(ButtonStates.enabled)
    }
    else setProceedingButtonState(ButtonStates.disabled)
  }, [
    getValidate, paymentMethodSelection,
    bankAccountPaymentValidation,
    creditCardPaymentValidation,
    payDeductionPaymentValidation,
    isBillingAddressSelected
  ])

  useEffect(() => {
    if (billingAddressValidation === true) {
      setValidationField(BILLING_ADDRESS_OPTION, isBillingAddressSelected)
      getValidate
        && (billingAddressValidation && isBillingAddressSelected) ?
        setProceedingButtonState(ButtonStates.enabled) : setProceedingButtonState(ButtonStates.disabled)
    }
  }, [
    getValidate,
    billingAddressOption,
    isBillingAddressSelected
  ])

  useEffect(() => {
    if (getPrefetchData[BILLING_ADDRESS_OPTION].value !== undefined) {
      setBillingAddressOption(getPrefetchData[BILLING_ADDRESS_OPTION].value)
      setIsBillingAddressSelected(getPrefetchData[BILLING_ADDRESS_OPTION].isValidated)
    }
    if (getPrefetchData[PAYMENT_METHOD_SELECTION].value !== undefined) {
      setPaymentMethodSelection(getPrefetchData[PAYMENT_METHOD_SELECTION].value)
    }
  }, [getPrefetchData[BILLING_ADDRESS_OPTION].value, getPrefetchData[PAYMENT_METHOD_SELECTION].value])

  useEffect(() => {
    if (billingAddressOption !== '') {
      setField(BILLING_ADDRESS_OPTION, billingAddressOption)
    }
    if (paymentMethodSelection !== '') {
      setField(PAYMENT_METHOD_SELECTION, paymentMethodSelection)
    }
  }, [billingAddressOption, paymentMethodSelection])

  useEffect(() => {
    let stateOfferParoll = (getStateFromForm1 === 'QLD' || getStateFromForm1 === 'TAS'
      || getStateFromForm1 === 'Newcastle')
    const payrollConditions = (getStateFromForm2 !== 'Salaried' && getEmployerSelectedOrTypedFromForm1 === 'selected')

    // Set prefered default selected payment method per branch
    if ((getStateFromForm1 === 'SA' || getStateFromForm1 === 'NT'
      || getStateFromForm1 === 'Broken Hill')
      && getPrefetchData[PAYMENT_METHOD_SELECTION]?.value === undefined) {
      setPaymentMethodSelection('Credit card')
    }
    else if ((getStateFromForm1 === 'WA' || getStateFromForm1 === 'Newcastle')
      && getPrefetchData[PAYMENT_METHOD_SELECTION]?.value === undefined) {
      setPaymentMethodSelection('Bank account')
    }

    // State offers payroll with the selected employer
    if (stateOfferParoll && getPayDeductionRequiredFromForm1 === 'true') {
      if (payrollConditions) {
        setPayrollDeductionTitle('Payroll deduction')
        if (getPrefetchData[PAYMENT_METHOD_SELECTION]?.value === undefined
          || getPrefetchData[PAYMENT_METHOD_SELECTION]?.value === 'Payroll deduction') {
          setPaymentMethodSelection('Payroll deduction')
        }
        setIsPayrollDeductionAvailable(true)
      } else {
        setPayrollDeductionTitle('')
        setIsPayrollDeductionAvailable(false)
      }
    }

  }, [getStateFromForm1, getStateFromForm2,
    getEmployerSelectedOrTypedFromForm1, getPayDeductionRequiredFromForm1])

  useEffect(() => {
    if (getPrefetchData[PAYMENT_METHOD_SELECTION].isValidated === true
      && paymentMethodSelection === 'Credit card') {
      if (['SA', 'WA', 'NT', 'QLD', 'Broken Hill', 'TAS'].includes(getStateFromForm1)) {
        setThirdPartyCCValidated(false)
      }
      setPaymentTab(1)
    }
    if (getPrefetchData[PAYMENT_METHOD_SELECTION].isValidated === true
      && paymentMethodSelection === 'Bank account') {
      setThirdPartyCCValidated(true)
      setPaymentTab(0)
    }
    if (getPrefetchData[PAYMENT_METHOD_SELECTION].isValidated === true
      && paymentMethodSelection === 'Payroll deduction' && payrollDeductionTitle === 'Payroll deduction') {
      setThirdPartyCCValidated(true)
      setPaymentTab(2)
    }
  }, [getPrefetchData[PAYMENT_METHOD_SELECTION].isValidated,
    paymentMethodSelection, payrollDeductionTitle])

    useEffect(() => {
      const cond = proceedingButtonState !== "disabled"
      setPageValidation(3, cond)
      onHandleFormValidated ? onHandleFormValidated(cond) : {}
    }, [proceedingButtonState])

  const setValidationField = (nameParam: string, wasAnInputSuccess: boolean) => {
    if (!wasAnInputSuccess) dispatch(setUnsuccessfullyPosted(3))

    dispatch(writeYourContributionData({
      key: nameParam,
      value: checkFetchData(nameParam) ? getPrefetchData[nameParam]?.value : undefined,
      isValidated: wasAnInputSuccess
    }
    ))
  }

  const setField = (nameParam: string, inputValue?: string) => {
    dispatch(writeYourContributionData({
      key: nameParam,
      value: inputValue ? inputValue : "",
      isValidated: true
    }
    ))
  }

  const setPageValidation = (pageNum: number, isValid: boolean) => {
    dispatch(writeValidateData({
      pageId: pageNum,
      isValidated: isValid,
      data: undefined
    }))
  }

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  return (
    <FormStep4Wrapper>
      <H3> Lastly, your contribution </H3>
      <CheckboxButtonGroup
        onSelectedLabel={selectedLabel => {
          setBillingAddressOption(selectedLabel)
          setIsBillingAddressSelected(true)
        }}
        inputFromProps={
          checkFetchData(BILLING_ADDRESS_OPTION) ?
            getPrefetchData[BILLING_ADDRESS_OPTION]?.value : null
        }
      />

      {
        (billingAddressOption === DIFFERENT_BILLING_ADDRESS_VALUE) &&
        <BillingAddressFields />
      }

      <PositioningRadioButtonGroup
        onSelectedValue={selectedValue => {
          setPaymentMethodSelection(selectedValue)
        }}
        onSelectTabProp={tabNumber => {
          setPaymentTab(tabNumber)
        }}
        label={'Payment method'}
        title1={'Bank account'}
        title2={'Credit card'}
        title3={payrollDeductionTitle}
        isPayrollDeductionAvailable={isPayrollDeductionAvailable}
        inputFromProps={
          checkFetchData(PAYMENT_METHOD_SELECTION) ?
            getPrefetchData[PAYMENT_METHOD_SELECTION]?.value : null
        }
        visibility={true}
      />
      {paymentTab === 0 &&
        <BankAccountTab />
      }

      {paymentTab === 1 &&
        <CreditCardPaymentTab />
      }

      {paymentTab === 2 &&
        <PayDeductionTab
          expandableParagraphPayrollDescriptionTextProp={branchSpecific(getStateFromForm1, 'paymentAgreement', 'payroll')}
        />
      }

      <CustomizedProgressButtonPositioningDiv
        style={{ zIndex: isIE ? 0 : -1 }}
      >
        <ProgressButton
          widthSize="13.875rem"
          title={progressButtonTilte}
          buttonStates={proceedingButtonState}
          stepNumber={3}
          onClickCallbackProp={() => {
            if (thirdPartyCCValidated) {
              setProceedingButtonState(ButtonStates.progressing)
              setProgressButtonTilte('Processing')
            } else {
              // handle 3rd party credit card validation
              setProceedingButtonState(ButtonStates.progressing)
              setProgressButtonTilte('Processing')
              // Fat Zebra credit card purchase
              if (['SA', 'NT', 'Broken Hill', 'QLD', 'WA', 'TAS'].includes(getStateFromForm1)) {
                const iframe = document.getElementById(iframeId)!
                iframe.style.height = `${iframeHeight}px`
                tokenizeCard()
                setTimeout(() => {
                  const { pageData } = store.getState().FormHighOrder
                  if (pageData?.[3]?.data?.creditCardTabData?.value?.paymentCreditCard_FatZebra_Token?.isValidated) {
                    handlePageSubmission(3).then((resultObj) => {
                      const canProceed = resultObj?.canProceed
                      if (resultObj?.isComplete && canProceed) {
                        dispatch(formSubmitted())
                        navigate(`/join-success/`, {
                          state: {
                            branch: getStateFromForm1
                          }
                        })
                      }
                    }).catch(errors => {
                      // onFailCallback ? onFailCallback(error) : undefined
                      if (typeof errors === "string") {
                        if (errors.includes('A member was found with this')) {
                          navigate(`/existing-user/`, {
                            state: {
                              branch: getStateFromForm1
                            }
                          })
                          return
                        }
                      }
                      if (errors?.find((error: any) => {
                        if (typeof error === 'string') {
                          return error?.includes('A member was found with this')
                        } else if (error?.message) {
                          return error?.message?.includes('A member was found with this')
                        }
                        return false
                      })) {
                        navigate(`/existing-user/`, {
                          state: {
                            branch: getStateFromForm1
                          }
                        })
                        return
                      }

                      navigate(`/error/`, {
                        state: {
                          errors: errors
                        }
                      })
                    })
                  } else {
                    // Card could not be tokenized
                    const iframe = document.getElementById(iframeId)!
                    iframe.style.height = `${iframeErrorHeight}px`
                    setProceedingButtonState(ButtonStates.enabled)
                    setProgressButtonTilte('Finish & submit')
                    jump('#fz-paynow', {
                      offset: -200,
                    })
                  }
                }, 1500)
              }
            }
          }}
          onClickExecFunc={thirdPartyCCValidated ? handlePageSubmission : undefined}
          onFailCallback={(error: any) => {
            // if (payDeductionPaymentValidation === true) {
            //   return 'Error'
            // }
          }}
        />
      </CustomizedProgressButtonPositioningDiv>
    </FormStep4Wrapper>
  )
}

export default FormStepFour

import React, { useState, useEffect, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { isEmpty } from 'lodash'
import { getMessageByName } from '../dataProcessing'
import { inputNumberOnly, inputNumberWithSpecialCharacter } from './InputNumberOnly'
import ValidationIndicator from '../ValidationIndicator'
import Tooltip from '../Tooltip'
import {
  validateEmail,
  validatePostcode, handleCreditCardNumber, validateExpiryDate,
  validateCreditCard, handleExpiryDate,
  NORMAL, EMAIL, POST_CODE, CARD_NUMBER, CARD_EXPIRY_DATE,
  CARD_CVC_CCC, onSubmitStrategy, CARD_ACCOUNT_NUMBER
} from './utils/'

import {
  unionPayRegex, dinersClubRegex,
  jcbRegex, visaMastercardRegex, discoverRegex, maestroRegex
} from './utils/cardRegexes'

import tooltipData from './tooltip.json'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

import {
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  BANKACCOUNT_PAYMENT_BSB,
  CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
  CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
  BILLING_UNIT_NUMBER,
  BILLING_POSTCODE,
  PAY_DEDUCTION_PAYMENT_NUMBER,
  BILLING_CITY
} from '../FormHighOrder/types/YourContributionField'
import InputField, { HoveringWrapper } from './styles/InputField'
import CardIcon from './styles/CardIcon'

export type Props = {
  placeholder: string
  widthSize?: string
  name: string
  maxLength?: number
  minLength?: number
  type?: string
  onInputSucessCallback?: (isSuccess: boolean) => void
  isVisible?: (isVisible: boolean) => void
  onShow?: (isShow: boolean) => void
  inputFromProps?: string
  value?: string
  onTypeText?: (textInputValue: string) => void
  readOnly?: boolean
  paddingSize?: string
  clickedProp?: boolean
  isValidated?: boolean
  onInputValueCallback?: (inputValue: string, isSuccess: boolean) => void
  getCityFromAddress?: string
  getPostCodeFromAddress?: string
  getUnitNumberFromAddress?: string
  hasValueChange?: (isChange: boolean) => void
  hideValidate?: any
  autoFocus?: boolean
  onFocus?: (event: any) => void
  onKeyDown?: (event: any) => void
  onBlur?: (event: any) => void
  onClick?: (event: any) => void
  onChange?: (event: any) => void
  identifyCard?: number
  getDate?: string
}

export type Ref = HTMLInputElement
// Is aRef used in any places anymore?
const TextInput = forwardRef<Ref, Props>((props: any, aRef: any) => {
  const [inputSuccess, setInputSuccess] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [textOutput, setTextOutput] = useState<any>(undefined);
  const [clicked, setClicked] = useState(false);
  const [labelIconState, setLabelIconStateState] = useState(true);
  const [hasInputChange, setInputChange] = useState(false)
  const [identifyCardState, setIdentifyCardState] = useState(0); // 1: visa | 2: mastercard | 3: amex
  const [showCreditCardIcons, setShowCreditCardIcons] = useState(false)
  const { isValidated, inputFromProps,
    onShow: onClickedCallback, clickedProp,
    onInputSucessCallback, onInputValueCallback, onTypeText, getCityFromAddress,
    hasValueChange, getPostCodeFromAddress, getUnitNumberFromAddress,
    onFocus, onKeyDown, onBlur, onClick, onChange, getDate } = props

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  useEffect(() => {
    const doesItHasPreInput = (inputFromProps && !isEmpty(inputFromProps))
    const isPreInputValidated = isValidated && isValidated !== false
    if (doesItHasPreInput && isPreInputValidated) {
      setTextOutput(inputFromProps)
      setClicked(false)
      setInputSuccess(isValidated)
      setVisibility(isValidated)
    }
    else {
      setTextOutput(inputFromProps)
      setInputSuccess(false)
    }
  }, [inputFromProps, isValidated]);

  useEffect(() => {
    if (getCityFromAddress !== undefined && getCityFromAddress !== '') {
      setTextOutput(getCityFromAddress)
      onInputValueCallback ? onInputValueCallback(getCityFromAddress, !!getCityFromAddress) : {}
      setInputSuccess(true)
      setVisibility(true)
    }
    if (getPostCodeFromAddress !== undefined && getPostCodeFromAddress !== '') {
      setTextOutput(getPostCodeFromAddress)
      onInputValueCallback ? onInputValueCallback(getPostCodeFromAddress, !!getPostCodeFromAddress) : {}
      setInputSuccess(true)
      setVisibility(true)
    }
    if (getUnitNumberFromAddress !== undefined && getUnitNumberFromAddress !== '') {
      setTextOutput(getUnitNumberFromAddress)
      onInputValueCallback ? onInputValueCallback(getUnitNumberFromAddress, !!getUnitNumberFromAddress) : {}
      setInputSuccess(true)
      setVisibility(true)
    }
  }, [getCityFromAddress, getPostCodeFromAddress, getUnitNumberFromAddress])

  useEffect(() => {
    onTypeText ? onTypeText(textOutput) : {}
    onInputSucessCallback ? onInputSucessCallback(inputSuccess) : {}
  }, [inputSuccess, textOutput])

  useEffect(() => {
    onClickedCallback ? onClickedCallback(clicked) : {}
  }, [clicked]);

  useEffect(() => {
    if (clickedProp === false) setClicked(clickedProp)
  }, [clickedProp]);

  useEffect(() => {
    hasValueChange ? hasValueChange(hasInputChange) : {}
  }, [hasInputChange])

  useEffect(() => {
    if (visibility === true && inputSuccess === false) {
      setLabelIconStateState(false)
    } else {
      setLabelIconStateState(true)
    }
  }, [inputSuccess, visibility])

  useEffect(() => {
    if (getDate) {
      setTextOutput(getDate)
    }
  }, [getDate])

  const handleMasking = (event: any) => {
    let inputGetter: string = event.target.value
    setInputChange(() => !!inputGetter)
    if (props.name === 'workSuburb') {
      inputGetter.length >= 3 ? setVisibility(true) : setVisibility(false);
      setTextOutput(inputGetter);
    }
    else if (props.name === 'address' || props.name === 'kinAddress') {
      inputGetter.length >= 2 ? setClicked(true) : setClicked(false);
      setTextOutput(inputGetter);
    }
    else if (
      props.name === 'creditCard' ||
      props.name === CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER
    ) {
      let creditCardNumber = handleCreditCardNumber(inputGetter, event)
      setTextOutput(creditCardNumber)
      if (creditCardNumber?.length >= 2) {
        let test = Number(creditCardNumber.substring(0, 2))
        let test2 = Number(creditCardNumber.substring(0, 4))
        if (unionPayRegex.test(creditCardNumber)) {
          setIdentifyCardState(4)
        }
        else if (test === 34 || test === 37) {
          setIdentifyCardState(3)
        }
        else if (dinersClubRegex.test(creditCardNumber)) {
          setIdentifyCardState(5)
        }
        else if (jcbRegex.test(creditCardNumber)) {
          setIdentifyCardState(6)
        }
        else if ((51 <= test && test <= 55) || (2221 <= test2 && test2 <= 2720)) {
          setIdentifyCardState(2)
        }
        else if (creditCardNumber.startsWith('4')) {
          setIdentifyCardState(1)
        }
        else if (visaMastercardRegex.test(creditCardNumber)) {
          setIdentifyCardState(7)
        }
        else if (discoverRegex.test(creditCardNumber)) {
          setIdentifyCardState(8)
        }
        else if (maestroRegex.test(creditCardNumber)) {
          setIdentifyCardState(9)
        } else {
          setIdentifyCardState(0);
        }
      } else {
        setIdentifyCardState(0);
      }
    }
    else if (
      props.name === 'expiryDate' ||
      props.name === CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE
    ) {
      let expiryDate = handleExpiryDate(inputGetter, event)
      setTextOutput(expiryDate)
    }
    else {
      setTextOutput(inputGetter);
    }
  }

  const onSubmit = (event: any) => {
    let inputGetter = event.target.value;
    const { name } = props
    const whitespaceRegex = /^\s*$/ig

    switch (onSubmitStrategy(name)) {
      case EMAIL:
        setInputSuccess(() => validateEmail(inputGetter))
        onInputValueCallback ? onInputValueCallback(inputGetter, validateEmail(inputGetter)) : {}
        break;
      case NORMAL:
        {
          const isValid = !!inputGetter && Boolean(inputGetter.match(whitespaceRegex) === null)
          setInputSuccess(() => isValid)
          // check that the user hasn't entered only whitespace
          onInputValueCallback ? onInputValueCallback(inputGetter, isValid) : {}
        }
        break;
      case POST_CODE:
        setInputSuccess(() => validatePostcode(inputGetter))
        onInputValueCallback ? onInputValueCallback(inputGetter, validatePostcode(inputGetter)) : {}
        break;
      case CARD_ACCOUNT_NUMBER:
        {
          const isValid = inputGetter.length >= 6 && !Number.isNaN(Number(inputGetter))
          setInputSuccess(() => isValid)
          onInputValueCallback ? onInputValueCallback(inputGetter, isValid) : {}
        }
        break;
      case CARD_NUMBER:
        const unformatCreditNumber = inputGetter.split(' ').join('');
        setInputSuccess(() => validateCreditCard(unformatCreditNumber, getStateFromForm1))
        onInputValueCallback ? onInputValueCallback(inputGetter, validateCreditCard(unformatCreditNumber, getStateFromForm1)) : {}
        break;
      case CARD_EXPIRY_DATE:
        const unformatExpiryDate = inputGetter.split(' ').join('');
        setInputSuccess(() => validateExpiryDate(unformatExpiryDate))
        onInputValueCallback ? onInputValueCallback(inputGetter, validateExpiryDate(unformatExpiryDate)) : {}
        break;
      case CARD_CVC_CCC:
        setInputSuccess(() => inputGetter.length === 3)
        onInputValueCallback ? onInputValueCallback(inputGetter, inputGetter.length === 3) : {}
        break;
    }
    setVisibility(true)
    setInputChange(false)
  }

  const onlyNumberKey = (event: any) => {
    if (
      props.name === 'creditCard'
      || props.name === 'phoneNumber'
      || props.name === 'kinPhoneNumber'
      || props.name === 'guardianPhoneNumber'
      || props.name === 'expiryDate'
      || props.name === 'cvc'
      || props.name === 'bsb'
      || props.name === 'accountNumber'
      || props.name === 'postcode'
      || props.name === 'kinPostcode'
      || props.name === 'kinUnitNumber'
      || props.name === BANKACCOUNT_PAYMENT_BSB
      || props.name === BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER
      || props.name === CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER
      || props.name === CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE
      || props.name === CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV
      || props.name === BILLING_POSTCODE
      || props.name === PAY_DEDUCTION_PAYMENT_NUMBER
    ) {
      inputNumberOnly(event)
    }
  }

  const onlyNumberKeyWithSpecialCharacter = (event: any) => {
    if (props.name === 'dateOnDesktop') {
      inputNumberWithSpecialCharacter(event)
    }
  }

  const onClickCallbackListener = () => {
    if (props.name === 'gender'
      || props.name === 'stateOrTerritory'
      || props.name === 'kinStateOrTerritory'
      || props.name === 'employmentStatus'
      || props.name === 'paymentCreditCard_DateOfDeduction'
      || props.name === 'paymentCreditCard_FrequencyOfDeduction'
      || props.name === 'paymentBankAccount_DateOfDeduction'
      || props.name === 'paymentBankAccount_FrequencyOfDeduction'
      || props.name === 'billing_stateOrTerritory'
    ) {
      setClicked(!clicked)
    }
  }

  const { message = {}, label, tooltipName, labelNotRequire, hideValidate } = props
  const msg = getMessageByName(message[props.name], inputSuccess)

  let widthSize = ''
  let tooltipTitle = ''
  let tooltip_message = ''

  if (
    tooltipName === 'cvc' ||
    tooltipName === CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV
  ) {
    widthSize = tooltipData.cvc[0].widthSize
    tooltipTitle = tooltipData.cvc[0].tooltipTitle
    tooltip_message = tooltipData.cvc[0].tooltip_message
  }

  return (
    <>
      {labelNotRequire && <Label>{labelNotRequire}</Label>}
      {label &&
        <Label>{label}
          <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon>
        </Label>}
      <HoveringWrapper>
        <InputField
          onChange={event => handleMasking(event)}
          onBlur={event => {
            onSubmit(event);
            onBlur && onBlur(event);
          }}
          onFocus={event => onFocus && onFocus(event)}
          placeholder={props.placeholder}
          widthSize={props.widthSize}
          paddingSize={props.paddingSize}
          name={props.name}
          isVisible={
            props.isVisible ? props.isVisible(visibility) : {}
          }
          type={props.type}
          maxLength={props.maxLength}
          minLength={props.minLength}
          inputSuccessAsBackgroundColor={inputSuccess}
          value={textOutput}
          onClick={(_: any) => {
            onClickCallbackListener();
            onClick && onClick(_);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
            onlyNumberKey(event);
            onlyNumberKeyWithSpecialCharacter(event);
          }}
          onKeyDown={() => onKeyDown && onKeyDown(event)}
          readOnly={props.readOnly}
          autoComplete='off'
          ref={aRef}
        />
        {identifyCardState !== 0 && <CardIcon identifyCard={identifyCardState} />}
      </HoveringWrapper>
      {tooltipName && <Tooltip {...{ tooltipName, widthSize, tooltipTitle, tooltip_message, visibility }} />}
      {msg
        && (!hideValidate && <ValidationIndicator
          isCorrect={inputSuccess} {...{ message: msg, visibility }}
        />)}
    </>
  )
})

export default TextInput

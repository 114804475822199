import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import ButtonItem from './ItemButton'
import Label from '../Label'
import ValidationIndicator from '../ValidationIndicator'
import color from '../color-css/color'

export const DIFFERENT_BILLING_ADDRESS_VALUE = "differentBillingAddress"
export const SAME_BILLING_ADDRESS_VALUE = "sameAsHomeBillingAddress"

const Container = styled.div`
  @media screen and (max-width: 690px) {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 0.625rem;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
`

const ContainerInfo = styled.div`
  display: flex,
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const LabelIcon = styled.span`
  color: ${color.blue_darker};
`

interface Props {
  className?: string
  onSelectedLabel?: (value: string) => void
  inputFromProps?: string
}

export const CheckboxButtonGroup: React.FC<Props> = ({
  className,
  onSelectedLabel,
  inputFromProps }) => {
  
  const [activeId, setActiveId] = React.useState(3)
  
  useEffect(() => {
    if(inputFromProps === "sameAsHomeBillingAddress") {
      setActiveId(0)
    } else if(inputFromProps === "differentBillingAddress"){
      setActiveId(1)
    }
  }, [inputFromProps])

  return (
    <div className={className}>
      <Label>
        Billing address
        {' '}
        <LabelIcon>*</LabelIcon>
      </Label>
      <Container>
        <ButtonItem
          active={activeId == 0}
          title={'Same as home address'}
          onPress={() => {
            setActiveId(0)
            onSelectedLabel ? onSelectedLabel('sameAsHomeBillingAddress') : {}
          }} />
        <ButtonItem
          active={activeId == 1}
          title={'Use a different billing address'}
          widthSize='16.0625rem'
          padding='1.0625rem 1.5625rem 1.0625rem 1.125rem'
          onPress={() => {
            setActiveId(1)
            onSelectedLabel ? onSelectedLabel('differentBillingAddress') : {}
          }} />
      </Container>
      <ContainerInfo>
        <ValidationIndicator
          visibility={activeId == 0 || activeId == 1}
          isCorrect={activeId == 0 || activeId == 1} 
        />

      </ContainerInfo>
    </div>
  )
}

export default CheckboxButtonGroup

const renderDateString = (d: any)=>{
  const mm = (d.getMonth() + 1).toString().padStart(2, '0')
  const dd = d.getDate().toString().padStart(2, '0')
  const yy = d.getFullYear()
  const myDateString = dd + ' / ' + mm + ' / ' + yy
  return myDateString
}

export const getDebitAmount = (summaryFrequency: string, fortnightRate: number, stateFromSuburb: string = 'unspecified') => {
  if (summaryFrequency === 'Fortnightly') {
    const rate = (Math.round(fortnightRate * 100) / 100).toFixed(2)
    return `$${rate}`
  } else if (summaryFrequency === 'Monthly') {
    let rate = (Math.round(fortnightRate * 2 * 100) / 100).toFixed(2)

    if(['WA', 'TAS'].includes(stateFromSuburb)) {
      // The calculation below is at the client's request.
      // For WA and TAS, they want the monthly rate to be a 1/12th of the yearly rate, rather than 4x the weekly rate (as it is for the other states above)
      rate = (Math.round(((fortnightRate * 26) / 12) * 100) / 100).toFixed(2)
    }

    return `$${rate}`
  } else if (summaryFrequency === 'Quarterly') {
    const rate = (Math.round(fortnightRate * 6 * 100) / 100).toFixed(2)
    return `$${rate}`
  } else { // Weekly
    const rate = (Math.round(fortnightRate / 2 * 100) / 100).toFixed(2)
    return `$${rate}`
  }
}

const getFirstOfNextMonth = (): string => {
  const date = new Date()
  const nextMonth = date.getMonth() < 11 ? (date.getMonth() + 1) : 0
  if (nextMonth === 0) {
    date.setFullYear(date.getFullYear() + 1)
  }
  date.setMonth(nextMonth)
  date.setDate(1)
  return renderDateString(date)
}

export const getStartDate = (dayInWeek: string) => {

  if (dayInWeek === 'noStartDate') {
    return null
  }

  if (dayInWeek === 'firstOfNextMonth') {
    return getFirstOfNextMonth()
  }

  const day = new Date()
  const formatDate = day.toLocaleTimeString('en-us', { weekday: 'long' })
  const currentDay = formatDate.substring(0 , formatDate.indexOf(' '))
  if (currentDay === dayInWeek) {
    const nextWeek = new Date()
    nextWeek.setDate(nextWeek.getDate() + 7)
    return renderDateString(nextWeek)
  }
  let dateInNumber: number
  const ret = new Date()
  switch(dayInWeek) {
  case 'Monday':
    dateInNumber = 1
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Tuesday':
    dateInNumber = 2
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Wednesday':
    dateInNumber = 3
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Thursday':
    dateInNumber = 4
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Friday':
    dateInNumber = 5
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Saturday':
    dateInNumber = 6
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  case 'Sunday':
    dateInNumber = 7
    ret.setDate(ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1)
    return renderDateString(ret)
  }
}
